import '@babel/polyfill';
import {handleChunkError} from 'app/app/appHelpers';

(async () => {
	try {
		await import(/* webpackChunkName: "authorization_app" */'app/authentication/app');
	} catch (error) {
		const store = window.reqres.request('get:redux:store');
		handleChunkError(error)(store.dispatch);
	}
})();
